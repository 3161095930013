import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Loader wrap=flex",
        "componentName": "Loader",
        "wrap": "flex"
      }}>{`<Loader />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Loader" accessibilityLabel="Loading article" mdxType="Props" />
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: medium`}</inlineCode></p>
    <p>{`Different cases call for different sizes. There are 5 available sizes for a
Loader.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Loader wrap=flex",
        "componentName": "Loader",
        "wrap": "flex"
      }}>{`<Loader size="small"/>
<Loader size="medium"/>
<Loader size="large"/>
<Loader size="xLarge"/>
<Loader size="xxLarge"/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      